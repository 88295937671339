




import { Component, Prop, Vue } from 'nuxt-property-decorator'

@Component({
  inheritAttrs: false,
})
export default class BlockTitle extends Vue {
  @Prop({ type: String }) title?: string
  @Prop({ type: Object }) data?: { title: string }

  get id() {
    return this.parsed.replace(/[^A-Z0-9]+/gi, '_').toLowerCase()
  }

  get parsed() {
    return this.title || (this.data && this.data.title) || ''
  }
}
