
























import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { Route } from 'vue-router'
// @ts-ignore:next-line
import ArrowSvg from '~/static/images/icons/arrow.svg?inline'

@Component({
  components: {
    ArrowSvg,
  },
})
export default class Breadcrumbs extends Vue {
  @Prop({ type: Array, default: () => [] }) routes!: {
    title: string
    href: Route
  }[]

  includedPage(pageTitle: string) {
    // Exclude 'Event' from breadcrumbs in Event Production page eg. /stage/event/a-number page
    if (pageTitle === 'event') {
      return false
    } else {
      return true
    }
  }

  // remove the alias ID from the end of event names
  formatTitle(pageTitle: string) {
    return this.routesToUse.find((r) => r.title === 'event')
      ? pageTitle.replace(/\d+$/, '')
      : pageTitle
  }

  // Remove the routes that have an empty title from the array
  get routesToUse(): {
    title: string
    href: Route
  }[] {
    return this.routes
      ? this.routes
          .filter((r) => r.title !== '')
          .filter((r) => r.title && this.includedPage(r.title))
      : []
  }

  formatReadWatchListen(pageTitle: string) {
    if (
      pageTitle === 'read' ||
      pageTitle === 'watch' ||
      pageTitle === 'listen'
    ) {
      return `/read-watch-listen/${pageTitle}`
    }
    return false
  }

  isInactive(index: Number) {
    return this.routesToUse.length - 1 === index ? '-1' : '0'
  }
}
