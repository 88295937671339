



















































import { Component, Vue, Prop, Watch } from 'nuxt-property-decorator'
import AccordionIcon from '@UI/components/accordions/AccordionIcon.vue'
import HeightTransition from '@UI/components/transitions/HeightTransition.vue'

@Component({
  components: {
    AccordionIcon,
    HeightTransition,
  },
})
export default class Accordion extends Vue {
  @Prop({ type: String, default: '' }) title!: string
  @Prop({ type: String, default: '' }) summary!: string
  @Prop({ type: String, default: '' }) accordionContent?: string
  @Prop({ required: true }) id!: string | number
  @Prop({ type: Array, default: () => [] }) icon?: Array<string>
  @Prop({ type: Boolean, default: false }) has_icon!: boolean
  @Prop({ type: Object, default: () => {} }) trigger_styles?: object
  @Prop({ type: Boolean, default: false }) showTriggerText!: boolean
  @Prop({ type: Boolean, default: false }) defaultOpen!: boolean
  @Prop({ type: Boolean, default: false }) isLoading!: boolean
  @Prop({ type: Boolean, default: false }) animate!: boolean
  @Prop({ type: Boolean, default: true }) hasTransition!: boolean
  @Prop({ type: Boolean, default: false }) lightTheme!: boolean
  @Prop({ type: Boolean }) closeAccordion!: boolean

  public isOpen: boolean = false

  open(): void {
    this.isOpen = true
  }

  close(): void {
    this.isOpen = false
  }

  @Watch('closeAccordion')
  handleClose(newVal) {
    if (newVal === true) {
      this.close()
    }
  }

  onClickToggle(e: PointerEvent, id): void {
    const parent = (<HTMLElement>e.target).parentElement
    const grandparent = parent?.parentElement
    if (
      this.isOpen &&
      parent &&
      !parent.classList.contains('accordion-block__content') &&
      !grandparent?.classList.contains('accordion-block__content')
    ) {
      this.close()
      this.$emit('closed', id)
    } else {
      this.open()
      this.$emit('opened', id)
    }
    this.$emit('toggled', this.isOpen)
  }

  get componentName(): string {
    return this.hasTransition ? 'HeightTransition' : 'div'
  }

  mounted(): void {
    if (this.defaultOpen) {
      // allow time for content to be fully loaded in
      // otehrwise height is off
      setTimeout(() => {
        this.isOpen = true
      }, 2000)
    }
  }
}
