



import { Component, Prop, Vue } from 'nuxt-property-decorator'

@Component
export default class AccordionIcon extends Vue {
  @Prop({ type: Boolean, default: false }) isOpen!: boolean
}
